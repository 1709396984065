<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 15:04:19
 * @LastEditTime: 2021-02-03 14:07:53
 * @LastEditors: fely
-->
<template>
  <div class="vip-page">
    <div class="header">
      <top-header :white="true"></top-header>
    </div>
    <div class="content">
      <div class="con-wrapper">
        <div class="sub-title">亿慧小秘书企业端VIP会员资费介绍</div>
        <div class="card-list">
          <div class="card">
            <div class="top small">
              <div class="top-one">VIP初级会员</div>
              <div class="top-two">10条招聘信息/天</div>
            </div>
            <div class="center">
              <div class="second-one">VIP其他功能免费使用</div>
              <div class="second-two">￥<span class="second-word">10</span>/月</div>
            </div>
            <div class="bottom">
              <div class="third-btn" @click="contact">咨询商务</div>
            </div>
          </div>
          <div class="card">
            <div class="top middle">
              <div class="top-one">VIP中级会员</div>
              <div class="top-two">100条招聘信息/天</div>
            </div>
            <div class="center">
              <div class="second-one">VIP其他功能免费使用</div>
              <div class="second-two">￥<span class="second-word">30</span>/月</div>
            </div>
            <div class="bottom">
              <div class="third-btn" @click="contact">咨询商务</div>
            </div>
          </div>
          <div class="card">
            <div class="top big">
              <div class="top-one">VIP高级会员</div>
              <div class="top-two">999条招聘信息/天</div>
            </div>
            <div class="center">
              <div class="second-one">VIP其他功能免费使用</div>
              <div class="second-two">￥<span class="second-word">90</span>/月</div>
            </div>
            <div class="bottom">
              <div class="third-btn" @click="contact">咨询商务</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" style="margin-top: 30px">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
export default {
  name: '',
  components: {
    TopHeader
  },
  data() {
    return {

    }
  },
  methods: {
    contact(){
      this.$message.info('咨询电话：022 23832459')
    }
  },
}
</script>

<style scoped lang="less">
.vip-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: relative;
    height: 60px;
    width: 100%;
    background-color: #fff;
  }
  .content{
    flex: 1;
    background-color: #F0F2F6;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    flex-direction: column;
    .con-wrapper{
      .sub-title{
        padding: 20px 0;
        color: #333;
        font-size: 16px;
        text-align: left;
      }
      .card-list{
        display: flex;
        .card{
          height:360px;
          width: 230px;
          margin-right: 40px;
          background-color: #fff;
          border-radius: 6px;
          overflow: hidden;
          .top{
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .top-one{
              font-size: 24px;
            }
            .top-two{
              margin-top: 18px;
              font-size: 14px;
            }
          }
          .center{
            height: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .second-one{
              font-size: 14px;
              color: #333;
            }
            .second-two{
              font-weight: 600;
              .second-word{
                font-size: 48px;
                
              }
            } 
          }
          .bottom{
            display: flex;
            justify-content: center;
            .third-btn{
              cursor: pointer;
              width: 192px;
              height: 44px;
              background: #4E90FF;
              opacity: 1;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 14px;
            }
          }
          .small{
            background: linear-gradient(131deg, #F9DCCC 0%, #EDC1A4 100%);
          }
          .middle{
            background: linear-gradient(139deg, #D4E8FE 0%, #8DB0DC 100%);
          }
          .big{
            background: linear-gradient(130deg, #529AFE 0%, #2070F6 100%);
          }
          .small,.middle,.big{
            height: 112px;
          }
        }
      }
    }
  }
}
.footer{
    // position: absolute;
    height: 27px;
    width: 100%;
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex;
    justify-content: center;
    padding-bottom: 27px;
    font-size: 13px;
    box-sizing: border-box;
  }
  a.beian,.footer{
    color: #939295;
  }
  a.beian:hover{
    text-decoration:underline;
  }
</style>
